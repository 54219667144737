import React, { Component } from "react";
import {Container, Row, Col,Image,Button,Form,Card,InputGroup,Collapse,OverlayTrigger,Tooltip  } from 'react-bootstrap';
import "../CadreRegistration/cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";
import { BrowserQRCodeReader } from "@zxing/library";
import { getAPICallDataForOnlyBSA } from '../../services/MemberService';
import CapturePhotoForScannedMembership from "./CapturePhotoForScannedMembership";
import loader from '../../images/loader.gif';
import "../EventsAndActivities/EventsAndActivities.css";
import SelectBox from '../SelectDropDown/SelectDropDown';
import { components } from 'react-select';
import CardLessDataTableWithOutSearch from '../datatables/CardLessDataTableWithOutSearch';
import { scroller } from "react-scroll";

const DropdownIndicator = props => {
  return (
      components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-6q0nyr-Svg"><path fill="currentColor" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
          </components.DropdownIndicator>
      )
  );
};

class CardDistributionSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
      webcamRef : React.createRef(),
      qrResult: "",
      scanning: true,
      membershipScannLoadingSymbol:"hide",
      membershipScannedDetailsObj:{},
      membershipScannDataCheck:0,
      authenticationMsg:"",
      capturePhotoModal:false,
      camaraRadioButtonNew:false,
      uploadImageRadioButtonNew:true,
      selectedTypePhotoNew:"upload",
      showActivityImgCaptureNew:true,
      manualUploadImageNew:"",
      photoCaptureForActivityArrNew:[],
      validateActivityImageUpload:"",
      validatePhotoCapture:"",
      saveCardDistributionLoadingSymbol:"hide",
      saveCardDistributionDisabled:false,
      imageBase64String:"",
      saveCardDistributionSuccessMsg:"",
     // locationStatus: "Checking Mobile Location Is Enabled or Disabled...",
      locationStatus:"Location is ON",
      getCurrentLocationCoordinates:{},

      printedCardType:true,
      qrCodeCardType:false,

      SearchBoothValue:"",
      searchOptionBoothListArr:[],
      boothsLoadingSymbol:"hide",
      volunteerSearchBoothName:"",

      printedCardsLoadingSymbol:"hide",
      printedCardsDataArr:[],
      printedCardDataCheck:0,
      searchPrintedCardValue:"",
      selectedtdpCadreId:0,
      selectedCadreLoading:"",
      savePrintedCardSuccessMsg:"",
      isDistributedAllStatusChecked:true,
      isDistributedYesStatusChecked:false,
      isDistributedNoStatusChecked:false,
      totalPrintedCardsArr:[],
      boothWisePrintedSummaryObj:{}
      
      
    };
    this.videoRef = React.createRef();
    this.codeReader = new BrowserQRCodeReader();
    this.locationTimeout = null;
    this.inputRef = React.createRef();
    
  }

  componentDidMount() {
    if(this.state.userAccessList == "" || this.state.campaignId == ""){
      this.props.history.push("/dashboard");
  }else{
    this.checkMobileLocation();
    
    if(this.state.userAccessList.boothList != null && this.state.userAccessList.boothList.length>0){
        let boothListArr=[];
        this.state.userAccessList.boothList.map(item => {
            const obj = {
                value: item.id,
                label: "Booth No -"+item.name,
            }
            boothListArr.push(obj);
            
        });
        this.setState({ searchOptionBoothListArr: boothListArr });
        
    } 
  }
}


  componentWillUnmount() {
    this.stopCamera();
  }

 
  

  checkMobileLocation = async () => {
    console.log("sssssssscccccccccc",this.state.locationTimeout)
    if (!navigator.geolocation) {
      this.setState({ locationStatus: "Geolocation is not supported by your browser." });
      return;
    }

    try {
      // Step 1: Check permission status
      if (navigator.permissions) {
        const permission = await navigator.permissions.query({ name: "geolocation" });

        if (permission.state === "denied") {
          this.setState({ locationStatus: "Location is OFF ❌ (Permission Denied). Enable location services." });
          return;
        }
      }

      console.log("sssssssscccccccccc1",this.state.locationTimeout)

      // Step 2: Set a timeout to detect if location is OFF (e.g., GPS disabled)
      this.locationTimeout = setTimeout(() => {
        this.setState({ locationStatus: "Location is OFF ❌ (GPS Disabled). Please turn it ON." });
      }, 6000); // Timeout after 5 seconds if no response

      // Step 3: Request location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          

          clearTimeout(this.locationTimeout); // Clear timeout if we get a response
          this.setState({ locationStatus: "Location is ON" });

          console.log("sssssssscccccccccc2",this.state.locationTimeout)
          const getCoordinatesdd =  
          { 
            "latitude" : position.coords.latitude,
            "longitude" : position.coords.longitude,
          }
          
          this.setState({getCurrentLocationCoordinates:getCoordinatesdd});
          

        },
        (error) => {
          clearTimeout(this.locationTimeout); // Clear timeout since we got an error
          console.log("sssssssscccccccccc3",this.state.locationTimeout)

          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = "Location is OFF ❌ (Access Denied). Please enable location services.";
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = "Location is OFF ❌ (Position Unavailable). Turn on GPS.";
              break;
            case error.TIMEOUT:
              errorMessage = "The request to get user location timed out,Please Close The BOT Then Reopen...";
              break;
            default:
              break;
          }
          this.setState({ locationStatus: errorMessage });
        },
        { timeout: 5000 } // Ensure the request times out if it hangs
      );
    } catch (error) {
      this.setState({ locationStatus: "Error checking location status." });
    }
  };

  
  cardDistributionBlock = (type) => {
    if(type == "printed"){
      this.setState({SearchBoothValue:"",
                    boothsLoadingSymbol:"hide",
                    volunteerSearchBoothName:"",
              
                    printedCardsLoadingSymbol:"hide",
                    printedCardsDataArr:[],
                    totalPrintedCardsArr:[],
                    boothWisePrintedSummaryObj:{},
                    printedCardDataCheck:0,
                    searchPrintedCardValue:"",
                    selectedtdpCadreId:0,
                    selectedCadreLoading:"",
                    savePrintedCardSuccessMsg:""});

      this.setState({printedCardType:true,qrCodeCardType:false})
    }else{
      setTimeout(() => {
          this.startCamera();  
      }, 1000);
      this.setState({printedCardType:false,qrCodeCardType:true})
    }
  }

  checkIsDistributedValue = (e,statusType) => {
    

    var usersArr = this.state.totalPrintedCardsArr;

    console.log("ssssss",statusType)
  
    if(statusType == ""){
      this.setState({isDistributedAllStatusChecked:true,isDistributedYesStatusChecked:false, isDistributedNoStatusChecked:false})
        
      setTimeout(() => {
        this.setState({printedCardsDataArr:usersArr})
      }, 800);
      
      
    }else if(statusType == "Y"){
      this.setState({isDistributedAllStatusChecked:false,isDistributedYesStatusChecked:true, isDistributedNoStatusChecked:false})
      var updatedStatusRecords = usersArr.filter(item => item.status	== "Y");
      setTimeout(() => {
        this.setState({printedCardsDataArr:updatedStatusRecords})
      }, 800);
     
    }else if(statusType == "N"){
      this.setState({isDistributedAllStatusChecked:false,isDistributedYesStatusChecked:false, isDistributedNoStatusChecked:true})
      var notupdatedStatusRecords = usersArr.filter(item => item.status == "N");
      setTimeout(() => {
        this.setState({printedCardsDataArr:notupdatedStatusRecords})
      }, 800);
     
    }
  

  }

  handleOnchangeSelectBoxNew1 = (ename, evalue) =>{
    const name = ename;
    const value = evalue;
  if(name == "volunteerSearchBoothName"){
        let getBoothIdName = this.state.searchOptionBoothListArr.find((a) => a.value == value);
        this.setState({
            SearchBoothValue: {
            value: getBoothIdName.value,
            label: getBoothIdName.label
        }
    });
       
    this.getCadreCardPrintDetailsByBoothId(value)
  }
      

    this.setState({ [name]: value});
  }

  handleFocus = () => {
    scroller.scrollTo("moveToPoint", { 
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
  });

  }

  
  handlesearchPrintedCardValue = (event) => {
   
  this.setState({
    searchPrintedCardValue: event.target.value
  })
}

  getCadreCardPrintDetailsByBoothId = (boothId) => {
    
    this.setState({printedCardsLoadingSymbol:"show",printedCardsDataArr:[],totalPrintedCardsArr:[],boothWisePrintedSummaryObj:{},printedCardDataCheck:0});
          
    const obj =  
      JSON.stringify({ 
        "boothId" : boothId
      }); 

    getAPICallDataForOnlyBSA(obj,"getCadreCardPrintDetailsByBoothId")
    .then((res) => res.json())
    .then((data) => {
      this.setState({printedCardsLoadingSymbol:"hide"});
      this.setState({authenticationMsg:""});

      if(data !=null && data.length>0){
          var printedCardsArr = data.map((item) => {
            return {
              ...item,
              checked: false,
              message:"",
              imageUploadChecked:false
          };
        });


        const updatedStatusCount = printedCardsArr.filter(item => item.status == "Y");
        var obj={"totalCadres":printedCardsArr.length,"updatedCount":updatedStatusCount.length}

        this.setState({boothWisePrintedSummaryObj:obj});
        
        
          this.setState({printedCardsDataArr:printedCardsArr,printedCardDataCheck:0,totalPrintedCardsArr:printedCardsArr});
      }else{
          this.setState({printedCardsDataArr:[],printedCardDataCheck:1,totalPrintedCardsArr:[],boothWisePrintedSummaryObj:{}});
      }
       

       
    });
  }

    
  printedCardDataColumns() {

    let columns = [
      
        {
            dataField: "cadreName",
            text: "Name",
            sort: true,
            formatter: this.actionsFormatterForNameDetails.bind(this),
            headerStyle: {
                width: "70%"
            }
            
        },
        {
            dataField: "status",
            text: "Is Distributed",
            sort: true,
            formatter: this.actionsFormatterForCardStatus.bind(this),
            headerStyle: {
                width: "30%"
            }
           
        }
        
    ];
    return columns;
}



actionsFormatterForNameDetails = (cell, row, rowIndex, formatExtraData) =>{
  return (
    <>
      <h6 class="text-center f-12">{(row.englishName)} </h6>
      <h6 class="f-10 mt-2 text-center f-12">RN - {(row.relativeName)} </h6>
      <h6 className='f-10 color_red text-center f-12 mt-2'>Membership - {(row.membershipId)}</h6>
      <h6 class="text-center f-12 mt-2" ><i className="fa-solid fa-phone" style={{color:"green"}} ></i> <a  style={{color:"green"}} href={`tel:${row.mobileNo}`}> {row.mobileNo}</a></h6>

      </>
   );
}


actionsFormatterForCardStatus = (cell, row, rowIndex, formatExtraData) => {
  return (
  <>
  {
      
      row.status == 'N' ?
      (
          <OverlayTrigger key={"top"} placement={"top"}
              overlay={
                  <Tooltip id={`tooltio-link123`}>
                  <h6 className='f-14'>Need To Change Card Distributed Status</h6>
                  </Tooltip>
              }
              >
              <>
                {
                  row.imageUploadChecked == false ?
                  (
                    <h6 className="f-12 color_orange text-decoration-underline cursor_pointer" onClick={() => this.capturePhotoForCadreEnroll(row.tdpCadreId)}> <i class="fa-solid fa-cloud-arrow-up"></i> Photo</h6>
                  )
                  :
                  (
                    <h6 className="f-12 color_green text-decoration-underline cursor_pointer" onClick={() => this.capturePhotoForCadreEnroll(row.tdpCadreId)}> <i class="fa-solid fa-cloud-arrow-up"></i> Photo <i className="fa-solid fa-circle-check "></i></h6>
                  )
                }
                

                <h6 className="f-12 color_red cursor_pointer mt-3 text-decoration-underline" onClick={() => this.savePrintedCardDistribution(row.tdpCadreId,row.membershipId)}> Submit</h6>
                  
                  {
                    row.checked == true ?
                        (
                          <Image src={loader} alt="loader" className='loader_cls'></Image>
                        )
                        :
                        ("") 
                  }

                  {
                    row.message != "" ?
                    (
                      <h6 className="f-12">{row.message}</h6>
                    )
                    :
                    ("")
                  }
                  
              </>
              
              </OverlayTrigger>
      )
      :
      (
          <Row>
              <Col sm={12}>
                  <div className=''>
                      <h6 className="f-12 color_green"> <i className="fa-solid fa-circle-check "></i> YES</h6>
                     
                      {
                        row.imageUploadChecked == false ?
                        (
                          <h6 className="f-12 color_orange text-decoration-underline cursor_pointer" onClick={() => this.capturePhotoForCadreEnroll(row.tdpCadreId)}> <i class="fa-solid fa-cloud-arrow-up"></i> Photo</h6>
                        )
                        :
                        (
                          <h6 className="f-12 color_green text-decoration-underline cursor_pointer" onClick={() => this.capturePhotoForCadreEnroll(row.tdpCadreId)}> <i class="fa-solid fa-cloud-arrow-up"></i> Photo <i className="fa-solid fa-circle-check "></i></h6>
                        )
                      }
                      

                      <h6 className="f-12 color_green cursor_pointer mt-3 text-decoration-underline" onClick={() => this.savePrintedCardDistribution(row.tdpCadreId,row.membershipId)}> Submit</h6>
                        
                        {
                          row.checked == true ?
                              (
                                <Image src={loader} alt="loader" className='loader_cls'></Image>
                              )
                              :
                              ("") 
                        }

                        {
                          row.message != "" ?
                          (
                            <h6 className="f-12">{row.message}</h6>
                          )
                          :
                          ("")
                        }
                  </div>
                  
              </Col>
              <Col>
                  
              </Col>
          </Row>
         
      )
  }
  </>
  )
}



printedCardData = (dataArr) => {
  console.log("dataArr",dataArr)
  let array = [];
      if(dataArr.length>0){
          for (let i = 0; i < dataArr.length; i++) {
              const obj = {
                cadreName: dataArr[i].cadreName,
                membershipId: dataArr[i].membershipId,
                mobileNo: dataArr[i].mobileNo,
                tdpCadreId: dataArr[i].tdpCadreId,
                status: dataArr[i].status,
                checked:dataArr[i].checked,
                message:dataArr[i].message,
                imageUploadChecked:dataArr[i].imageUploadChecked,
                englishName:dataArr[i].englishName,
                relativeName:dataArr[i].relativeName
                
              }
              array.push(obj);
          } 

          
          
          return array;
      }
}


  stopCamera = () => {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
    }
    this.codeReader.reset();
  };

  startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        const video = this.videoRef.current;
        video.srcObject = stream;
        video.setAttribute("playsinline", true); // For iOS
        video.play();
        this.scanQRCodeMainBox();
      })
      .catch((err) => console.error("Camera Access Error:", err));
  };

  scanQRCodeMainBox = () => {
    this.codeReader.decodeFromVideoDevice(
      undefined,
      this.videoRef.current,
      (result, err) => {
        if (result) {
          this.setState({ qrResult: result.text });
          console.log('QR Code detected:', result.text);
         
          var membershipNoVal="";
          var splitMemberShip = "";
          var url = result.text

          splitMemberShip = url.split("=")

          membershipNoVal = splitMemberShip[1];

          console.log('jjjj',membershipNoVal)

          if(membershipNoVal != ""){
              if(membershipNoVal.length == 8){
                  console.log(membershipNoVal)
                  if(membershipNoVal != "https://"){
                    this.setState({membershipNoTextUpdated:membershipNoVal})
                    console.log("yyyyyyyyyyyyyyyyyy")
                    setTimeout(() => {
                        this.setState({scanning: false})
                        this.stopCamera();
                        this.getCadreDetailsByMembershipId(membershipNoVal);
                   }, 800);
                     
                  }
                  
              }else if(membershipNoVal.length == 7){
                console.log(membershipNoVal)
                  if(membershipNoVal != "https://"){
                    var appendZeroMId = 0+membershipNoVal;
                    this.setState({membershipNoTextUpdated:appendZeroMId})
                    console.log("yyyyyyyyyyyyyyyyyy")
                    setTimeout(() => {
                        this.setState({scanning: false})
                        this.stopCamera();
                        this.getCadreDetailsByMembershipId(appendZeroMId);
                   }, 800);
                     
                  }
              }
          }

          
        }
      }
    );
  };

  getCadreDetailsByMembershipId = (membershipNoVal) => {

     this.setState({membershipScannLoadingSymbol:"show",membershipScannedDetailsObj:{},membershipScannDataCheck:0});
          
          const obj =  
          JSON.stringify({ 
            "membershipId" : membershipNoVal
          }); 
      
          getAPICallDataForOnlyBSA(obj,"getCadreDetailsByMembershipId")
          .then((res) => res.json())
          .then((data) => {
            this.setState({membershipScannLoadingSymbol:"hide"});
              this.setState({authenticationMsg:""});
              console.log("data",data);

              if(Object.keys(data).length > 0){
                if(data.status == "Fail"){
                    this.setState({
                        qrResult: "",
                        scanning: false,
                        membershipScannLoadingSymbol:"hide",
                        membershipScannedDetailsObj:{},
                        membershipScannDataCheck:0,
                        capturePhotoModal:false,
                        camaraRadioButtonNew:false,
                        uploadImageRadioButtonNew:true,
                        selectedTypePhotoNew:"upload",
                        showActivityImgCaptureNew:true,
                        manualUploadImageNew:"",
                        photoCaptureForActivityArrNew:[],
                        validateActivityImageUpload:"",
                        validatePhotoCapture:"",
                        saveCardDistributionLoadingSymbol:"hide",
                        saveCardDistributionDisabled:false,
                        imageBase64String:"",
                        saveCardDistributionSuccessMsg:""
                        
                    })
                    this.setState({authenticationMsg:data.message})
                }else{
                    this.setState({membershipScannedDetailsObj:data,voterIdSearchDataCheck:0});
                }
              }else if(Object.keys(data).length == 0){
                alert(1)
                this.setState({membershipScannedDetailsObj:{},voterIdSearchDataCheck:1});
              }
              else{
                this.setState({membershipScannedDetailsObj:{},voterIdSearchDataCheck:1});
              }

             
          });
      }


      
capturePhotoForCadreEnroll = (tdpCadreId) => {
    this.setState({capturePhotoModal:true,validatePhotoCapture:"",selectedtdpCadreId:tdpCadreId})
}
 

uploadImageForMembership = () => {
    if(this.state.selectedTypePhotoNew == "camara"){
        if(this.state.photoCaptureForActivityArrNew.length == 0){
            this.setState({validateActivityImageUpload:"Please Take A Photo..."});
            return;
        }
    }else if(this.state.selectedTypePhotoNew == "upload"){
        if(this.state.photoCaptureForActivityArrNew.length == 0){
            this.setState({manualUploadImageNew:"Please Upload Photo..."});
            return;
        }
    }

    console.log("aaaaaaaa",this.state.photoCaptureForActivityArrNew)

    setTimeout(() => {
        if(this.state.photoCaptureForActivityArrNew.length > 0){
            for(var i in this.state.photoCaptureForActivityArrNew){
                if(this.state.selectedTypePhotoNew == "upload"){
                    this.setState({"imageBase64String": this.state.photoCaptureForActivityArrNew[i].data.replace("data:"+this.state.photoCaptureForActivityArrNew[i].fileType+";base64,","")});
                }else{
                    this.setState({"imageBase64String": this.state.photoCaptureForActivityArrNew[i].data.replace("data:image/jpeg;base64,","")});
                }
                
            }
        }

        var data = this.state.printedCardsDataArr.map((item) => {
          if (item.tdpCadreId == this.state.selectedtdpCadreId) {
              return {
                  ...item,
                  imageUploadChecked:true
              };
          }
          return item;
      });

      this.setState({printedCardsDataArr:data})
    

    }, 1000);

    

    this.setState({capturePhotoModal:false})
}
 
selectCaptureTypeNew = (type) => {
    this.setState({photoCaptureForActivityArrNew:[],manualUploadImageNew:"",validateActivityImageUpload:""});
   
        
    if(type == "upload"){
      this.setState({ camaraRadioButtonNew:false,uploadImageRadioButtonNew:true});
      this.setState({showActivityImgCaptureNew:false});
    }else{
      this.reloadCam()
      this.setState({ camaraRadioButtonNew:true,uploadImageRadioButtonNew:false});
      this.setState({showActivityImgCaptureNew:true});
    }
    

    this.setState({selectedTypePhotoNew:type});
  }

  toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onloadend = () => resolve(reader.result)
  reader.onerror = reject
  reader.readAsDataURL(blob)
}))

uploadMultipleFilesForPhotosNew = async(e) => {
    this.setState({photoCaptureForActivityArrNew:[]});
    this.setState({manualUploadImageNew:""});
    const getUID = (length=8) => {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

    let fileObj=[];
      fileObj.push(e.target.files);
          for (let i = 0; i < fileObj[0].length; i++) {

            if(fileObj[0][i].type == "image/jpeg" || fileObj[0][i].type == "image/png" || fileObj[0][i].type == "image/jpg"){
                    this.toDataURL(URL.createObjectURL(fileObj[0][i]))
                    .then(async (dataUrl)  => {
                        var obj ={
                            fileId:getUID(),
                            fileType:fileObj[0][i].type,
                            data:dataUrl,
                        }
                        this.setState({photoCaptureForActivityArrNew:[...this.state.photoCaptureForActivityArrNew, obj]});

                        this.setState({manualUploadImageNew:"Photo Upload Successfully..."});
                    });
            } 
          }
  }

  captureForActivityImageUploadNew = async () => {
      this.setState({photoCaptureForActivityArrNew:[]}); 
      this.setState({showActivityImgCaptureNew:true});
  
  
      const getUID = (length=8) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }
  
      var obj={
          fileId : getUID(),
          data : this.state.webcamRef.current.getScreenshot(),
        }
  
        setTimeout(() => {
            this.setState({photoCaptureForActivityArrNew:[...this.state.photoCaptureForActivityArrNew, obj]}); 
            //this.setState({validateActivityImageUpload:""});
        }, 500);

      this.setState({validateActivityImageUpload:"Photo Capture Successfully..."});
  };

  reloadCam = () => {
    const constraints = {
        audio: false,
        video: true,
      };
    
      
    
    //this.setState({videoConstraints:{facingMode: "environment"}});
    navigator.mediaDevices
    .getUserMedia(constraints)
    .then((mediaStream) => {
        const video = document.querySelector("video");
        video.srcObject = mediaStream;
        video.onloadedmetadata = () => {
        video.play();
        };
    })
    .catch((err) => {
        // always check for errors at the end.
        console.error(`${err.name}: ${err.message}`);
    });
      
}

reloadMembershipCardScan = () => {
    this.setState({
        qrResult: "",
        scanning: true,
        membershipScannLoadingSymbol:"hide",
        membershipScannedDetailsObj:{},
        membershipScannDataCheck:0,
        authenticationMsg:"",
        capturePhotoModal:false,
        camaraRadioButtonNew:false,
        uploadImageRadioButtonNew:true,
        selectedTypePhotoNew:"upload",
        showActivityImgCaptureNew:true,
        manualUploadImageNew:"",
        photoCaptureForActivityArrNew:[],
        validateActivityImageUpload:"",
        validatePhotoCapture:"",
        saveCardDistributionLoadingSymbol:"hide",
        saveCardDistributionDisabled:false,
        imageBase64String:"",
        saveCardDistributionSuccessMsg:"",
        
    });
    setTimeout(() => {
        this.startCamera();  
    }, 800);
}

saveCardDistribution = () => {
    
    this.setState({saveCardDistributionLoadingSymbol:"show"});
    this.setState({saveCardDistributionDisabled:true})
    
      const addNewVoterObj =  
        JSON.stringify({ 
            "boothId" : this.state.membershipScannedDetailsObj.boothId,
            "tdpCadreId" : this.state.membershipScannedDetailsObj.tdpCadreId,
            "membershipId" : this.state.membershipScannedDetailsObj.membershipId,
            "latitude" : this.state.getCurrentLocationCoordinates.latitude == undefined ? "" : this.state.getCurrentLocationCoordinates?.latitude.toString(),
            "longitude" : this.state.getCurrentLocationCoordinates.longitude == undefined ? "" : this.state.getCurrentLocationCoordinates?.longitude.toString(),
            "base64Str" : this.state.imageBase64String
        }
      ); 
    
      
    
      //addNewVoter
      getAPICallDataForOnlyBSA(addNewVoterObj,"saveCardDistribution")
          .then((res) => res.json())
          .then(async(data) => {
            this.setState({saveCardDistributionLoadingSymbol:"hide"})
            if(data !=null){
              if(data.status == "Success"){
                this.setState({saveCardDistributionSuccessMsg:"Scanned Card SuccessFully..." });
               
              }else if(data.status == "Fail"){
                this.setState({saveCardDistributionDisabled:false})
                if(data.message == "Invalid Inputs"){
                  this.setState({saveCardDistributionSuccessMsg:"Something Wrong,Please Try Again..." });
                }else{
                  this.setState({saveCardDistributionSuccessMsg:data.message });
                }
                
              }
            }else{
              this.setState({saveCardDistributionDisabled:false})
              this.setState({saveCardDistributionSuccessMsg:"Something Wrong,Please Try Again..." });
            }
           
            
            
      });
}


savePrintedCardDistribution = (tdpCadreId,membershipId) => {
  setTimeout(() => {

   this.setState({savePrintedCardSuccessMsg:""});
  

    var data = this.state.printedCardsDataArr.map((item) => {
      if (item.tdpCadreId == tdpCadreId) {
          return {
              ...item,
              checked: true,
          };
      }
      return item;
  });

  this.setState({printedCardsDataArr:data})


    const addNewVoterObj =  
      JSON.stringify({ 
          "boothId" : this.state.volunteerSearchBoothName,
          "tdpCadreId" : tdpCadreId,
          "membershipId" : membershipId,
          "latitude" : this.state.getCurrentLocationCoordinates.latitude == undefined ? "" : this.state.getCurrentLocationCoordinates?.latitude.toString(),
          "longitude" : this.state.getCurrentLocationCoordinates.longitude == undefined ? "" : this.state.getCurrentLocationCoordinates?.longitude.toString(),
          "base64Str" : this.state.imageBase64String
      }
    ); 
    //addNewVoter
    getAPICallDataForOnlyBSA(addNewVoterObj,"saveCardDistribution")
        .then((res) => res.json())
        .then(async(data) => {
          if(data !=null){
            if(data.status == "Success"){
              setTimeout(() => {
                var new_updated_data = this.state.printedCardsDataArr.map((item) => {
                    if (item.tdpCadreId == tdpCadreId) {
                        return {
                            ...item,
                            status: 'Y',
                            checked:false,
                            message:"Updated"
                        };
                    }
                    return item;
                });
                this.setState({printedCardsDataArr:new_updated_data})
                this.setState({photoCaptureForActivityArrNew:[],manualUploadImageNew:"",validateActivityImageUpload:""});

                const updatedStatusCount = new_updated_data.filter(item => item.status == "Y");
                var obj={"totalCadres":new_updated_data.length,"updatedCount":updatedStatusCount.length}

                this.setState({boothWisePrintedSummaryObj:obj});
                
            }, 1000);

            }else{
                  var failedData = this.state.printedCardsDataArr.map((item) => {
                      if (item.tdpCadreId == tdpCadreId) {
                          return {
                              ...item,
                              checked:false,
                              message:"Not-Updated"
                          };
                      }
                      return item;
                  });
                  this.setState({printedCardsDataArr:failedData})
           }
        }else{
          var failedData = this.state.printedCardsDataArr.map((item) => {
              if (item.tdpCadreId == tdpCadreId) {
                  return {
                      ...item,
                      checked:false,
                      message:"Not-Updated"
                  };
              }
              return item;
          });
          this.setState({printedCardsDataArr:failedData})
        }
         
          
          
    });
    
  }, 800);
    


   
}


reloadCDSPage = () => {
    this.props.history.push("/dashboard");
  }

  render() {
    console.log("authenticationMsg",this.state.authenticationMsg)

    let manualPrintedCardsDataArr=[];
    let keyword = this.state.searchPrintedCardValue.toString()
    if (keyword !== ''){
          manualPrintedCardsDataArr = this.state.printedCardsDataArr.filter(  (item) => {
            
            if (item.englishName.toLowerCase().startsWith(keyword.toLowerCase())) {
                return item;
            }
            if (item.relativeName.toLowerCase().startsWith(keyword.toLowerCase())) {
              return item;
          }
            if (item.membershipId.toLowerCase().startsWith(keyword.toLowerCase())) {
                return item;
            }
            if (item.mobileNo.toString().startsWith(keyword.toLowerCase())) {
                return item;
            }
           
          
            
        });
    }else{
      manualPrintedCardsDataArr = this.state.printedCardsDataArr;
        
    }

    console.log("selectedtdpCadreId",manualPrintedCardsDataArr)
    
    return (
      <section className="mb-4">
                <div className='p-1'>
                    <Container>
                        <Row>
                            <Col>
                                <div className='bor-bottom-yellow-pad'>
                                    <h5 className='text-center color_red text-uppercase'>Card Distribution</h5>
                                </div>
                                
                            </Col>
                        </Row>
                        {
                             this.state.locationStatus == "Location is ON" ? 
                             (
                                <>  

                                    {
                                        this.state.authenticationMsg == "Authentication Failed" ? 
                                        (
                                            <Row>
                                                <Col>
                                                    <h4 className='color_red mt-3'>Session Expired...Please Close Then Reopen Telegram Bot</h4>
                                                </Col>
                                            </Row>
                                        )
                                        :
                                        (
                                            <>
                                                 <Row>
                                                  <Col sm={12} className='mt-3'>
                                                        <div className='flex_programs_block'>
                                                          <div className={this.state.printedCardType == true ? "border_bottom_my cusor_pointer" : "cusor_pointer"} onClick={() => this.cardDistributionBlock("printed")}><h6 className='fw-bold f-12 text-center'> <i class="fa-solid fa-id-card"></i> <span className='ms-2'>PRINTED CARDS</span></h6></div>
                                                          <div className={this.state.qrCodeCardType == true ? "border_bottom_other cusor_pointer" : "cusor_pointer"} onClick={() => this.cardDistributionBlock("scan")}><h6 className='fw-bold f-12 text-center'> <i class="fa-solid fa-qrcode"></i> <span className='ms-2'>QR CODE</span></h6></div>
                                                        </div>
                                                    </Col>
                                                 </Row>

                                                 {
                                                    this.state.printedCardType == true ?
                                                    (
                                                      <>
                                                      <Row>
                                                          <Col className='mt-3 moveToPoint' sm={4}>
                                                            <label className="form-label f-12 fw-bold">Select Booth<span className='color_red f-14 ms-1'>*</span>
                                                                {
                                                                    this.state.boothsLoadingSymbol == "show" ?
                                                                    (<Image src={loader} alt="loader" className='loader_cls ms-2 float-end'></Image>)
                                                                    :
                                                                    ("")
                                                                }
                                                                
                                                            </label>
                                                            <SelectBox
                                                                    stateData={this.state.SearchBoothValue}
                                                                    handleChange={this.handleOnchangeSelectBoxNew1}
                                                                    name="volunteerSearchBoothName"
                                                                    options={this.state.searchOptionBoothListArr}
                                                                    placeholderName={'Booth'}
                                                                    dropdownIndicator={{ DropdownIndicator }}
                                                                />
                                                        </Col>
                                                      </Row>

                                                          {
                                                            this.state.volunteerSearchBoothName != "" ?
                                                            (
                                                            <>
                                                                

                                                                <Row>
                                                                    <Col sm={12}>
                                                                
                                                                    <div className='bg_light_blue mt-3'>
                                                                            {
                                                                                this.state.printedCardsLoadingSymbol == "show" && this.state.printedCardsDataArr.length == 0  ?
                                                                                (
                                                                                    <Row>
                                                                                        <Col sm={12}>
                                                                                            <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )
                                                                                :
                                                                                ("")
                                                                            }
                                                                            <div >
                                                                            <Row>
                                                                                <Col sm={6}>
                                                                                    <input
                                                                                      onFocus={this.handleFocus}
                                                                                        className='float-end search_bar mt-2'
                                                                                        type="search"
                                                                                        placeholder='Search'
                                                                                        value={this.state.searchPrintedCardValue}
                                                                                        onChange={this.handlesearchPrintedCardValue}
                                                                                        
                                                                                    />
                                                                                    <i className='fa fa-search search-icon'></i>
                                                                                </Col>
                                                                            </Row>
                                                                            </div>

                                                                            <>
                                                                               
                                                                                    <Row>
                                                                                        <Col sm={12}>
                                                                                            <div className='bg_light_blue mt-1'>
                                                                                            <h6 className='f-14'> {this.state.SearchBoothValue.label} Printed Cadre Details : </h6>
                                                                                                {
                                                                                                  Object.keys(this.state.boothWisePrintedSummaryObj).length > 0 ?
                                                                                                  (
                                                                                                    <div>
                                                                                                    <h6 className='f-14 mt-3'>Summary : </h6>
                                                                                                    <table className='table table_custom table-bordered'>
                                                                                                      <thead>
                                                                                                          <tr>
                                                                                                            <th>Total</th>
                                                                                                            <th>Distributed</th>
                                                                                                            <th>Not Distributed</th>
                                                                                                          </tr>
                                                                                                      </thead>
                                                                                                      <tbody>
                                                                                                          <tr>
                                                                                                            <td className="color_anchor" >{this.state.boothWisePrintedSummaryObj.totalCadres}</td>
                                                                                                            <td className="color_anchor" >{this.state.boothWisePrintedSummaryObj.updatedCount}</td>
                                                                                                            <td className="color_anchor" >{this.state.boothWisePrintedSummaryObj.totalCadres - this.state.boothWisePrintedSummaryObj.updatedCount}</td>
                                                                                                          </tr>
                                                                                                      </tbody>
                                                                                                    </table>
                                                                                                  </div>
                                                                                                  )
                                                                                                  :
                                                                                                  ("")
                                                                                                }
                                                                                                 
                                                                                               
                                                                                                <h6 className='mt-3'>
                                                                                                    {/*<span className="form-label f-12 fw-bold ml-0">
                                                                                                        Is Distributed : 
                                                                                                    </span>*/}
                                                                                                    <span className='me-4'>
                                                                                                        <Form.Check
                                                                                                            inline
                                                                                                            label={"All"}
                                                                                                            name="isDistributed"
                                                                                                            type="radio"
                                                                                                            id="isDistributedAllStatus"
                                                                                                            onChange={(e)=>this.checkIsDistributedValue(e,'')}
                                                                                                            className="color_black f-12 pos_name_align"
                                                                                                            checked={this.state.isDistributedAllStatusChecked}
                                                                                                        />
                                                                                                        <Form.Check
                                                                                                            inline
                                                                                                            label={"Distributed"}
                                                                                                            name="isDistributed"
                                                                                                            type="radio"
                                                                                                            id="isDistributedYesStatus"
                                                                                                            onChange={(e)=>this.checkIsDistributedValue(e,'Y')}
                                                                                                            className="color_black f-12 pos_name_align"
                                                                                                            checked={this.state.isDistributedYesStatusChecked}
                                                                                                        />
                                                                                                        
                                                                                                        <Form.Check
                                                                                                            inline
                                                                                                            label={"Not Distributed"}
                                                                                                            name="isDistributed"
                                                                                                            type="radio"
                                                                                                            id="isDistributedNoStatus"
                                                                                                            onChange={(e)=>this.checkIsDistributedValue(e,'N')}
                                                                                                            className="color_black f-12 pos_name_align"
                                                                                                            checked={this.state.isDistributedNoStatusChecked}
                                                                                                        />
                                                                                                    </span>
                                                                                                </h6>
                                                                                                    
                                                                                                  {
                                                                                                    manualPrintedCardsDataArr.length > 0 ?
                                                                                                    (
                                                                                                      <CardLessDataTableWithOutSearch 
                                                                                                          parentData={this.printedCardData(manualPrintedCardsDataArr)}
                                                                                                          particlecolumns={this.printedCardDataColumns()}
                                                                                                          defaultSorted={[{
                                                                                                              dataField: "Name",
                                                                                                              order: "desc"
                                                                                                          }]}
                                                                                                          tableHeading=""
                                                                                                          />
                                                                                                    )
                                                                                                    :
                                                                                                    (
                                                                                                      <>
                                                                                                        {
                                                                                                          this.state.boothWisePrintedSummaryObj?.updatedCount == 0 ?
                                                                                                          (<h6 className='f-14 text-center mt-4'>No Membership cards are being checked...</h6>)
                                                                                                          :
                                                                                                          ("")
                                                                                                        }
                                                                                                        {
                                                                                                         this.state.boothWisePrintedSummaryObj?.totalCadres == this.state.boothWisePrintedSummaryObj?.updatedCount?
                                                                                                          (<h6 className='f-14 text-center mt-4'>All Membership cards are checked...</h6>)
                                                                                                          :
                                                                                                          ("")
                                                                                                        }
                                                                                                      </>
                                                                                                      
                                                                                                    )

                                                                                                  }
                                                                                               
                                                                                                
                                                                                                
                                                                                            </div>     
                                                                                        </Col> 

                                                                                            

                                                                                </Row>
                                                                                </>

                                                                                
                                                                        </div>
                                                                                                
                                                                    </Col>
                                                                </Row>
                                                                </>
                                                            )
                                                            :
                                                            ("")
                                                          }
                                                          </>
                                                    )
                                                    :
                                                    (
                                                      <>
                                                        {
                                                          this.state.scanning == true ? 
                                                          (
                                                              <Row>
                                                                  <Col>
                                                                  <h5 className='text-center mt-3 text-uppercase fw-bold'>Scan Your membership Card</h5>
      
                                                                  <div style={styles.scannerContainer}>
                                                                      <video ref={this.videoRef} style={styles.video} />
                                                                      <div style={styles.overlay}>
                                                                      <div style={styles.scannerFrame}>
                                                                          {/* Scanner Border Corners */}
                                                                          <div className="corner top-left"></div>
                                                                          <div className="corner top-right"></div>
                                                                          <div className="corner bottom-left"></div>
                                                                          <div className="corner bottom-right"></div>
                                                                          
                                                                      </div>
                                                                      </div>
                                                                  </div>
                                                                      
                                                                  </Col>
                                                              </Row>
                                                          )
                                                          :
                                                          ("")
                                                      }
                                                      {
                                                          this.state.scanning == false ?
                                                          (
                                                              <>
                                                              {
                                                                  this.state.membershipScannLoadingSymbol == "show" ?
                                                                  (
                                                                      <Row>
                                                                          <Col sm={12}>
                                                                              <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                                          </Col>
                                                                      </Row>
                                                                  )
                                                                  :
                                                                  ("")
                                                              }
                                                              {
                                                                  Object.keys(this.state.membershipScannedDetailsObj).length > 0 ?
                                                                  (
                                                                      
                                                                      <>
                                                                      <Row>
                                                                          <Col>
                                                                              <h6 className='color_green mt-2 text-uppercase fw-bold text-center'>Your Membership Details</h6>
                                                                          </Col>
                                                                      </Row>
                                                                      <Row>
                                                                          <Col>
                                                                              <div className="border_yash_pad mt-2">
                                                                                  <h6 className='f-14'>Name : {this.state.membershipScannedDetailsObj.cadreName}</h6>
                                                                                  <h6 className='f-14 mt-2'>Membership Id : {this.state.membershipScannedDetailsObj.membershipId}</h6>
                                                                                  <h6 className='f-14 mt-2'>Booth : {this.state.membershipScannedDetailsObj.boothName}</h6>
                                                                                  <h6 className='f-14 mt-2'>Assembly : {this.state.membershipScannedDetailsObj.constituencyName}</h6>
      
                                                                              </div>
                                                                          </Col>
                                                                      </Row>
                                                                      
                                                                      <Row>
                                                                          <Col>
                                                                              <div className='bg_white_shadow_border mt-2'>
                                                                              <h6 className='f-14 fw-bold text-center'>UPLOAD A PHOTO <i class="fa-solid fa-camera color_green f-28 cursor_pointer m_left_8 pos_top4" onClick={() => this.capturePhotoForCadreEnroll()}></i> </h6>
                                                                                  {
                                                                                      this.state.validatePhotoCapture != "" ?
                                                                                      (<h6 className='error_cls_new'>{this.state.validatePhotoCapture}</h6>)
                                                                                      :
                                                                                      ("")
                                                                                  }
                                                                                      <Row>
                                                                                          <Col sm={12} className="">
                                                                                          <div className='flex_img'>
                                                                                              {(this.state.photoCaptureForActivityArrNew || []).map((url,index) => (
                                                                                                  <div className='ms-1 border_img_pad'>
                                                                                                      <img src={url.data} className='img-thumbnail' alt="captureImg"></img>
                                                                                                  </div>
                                                                                              ))} 
                                                                                          </div>
                                                                                      </Col>
                                                                                  </Row>
                                                                              </div>
                                                                          </Col>
                                                                      </Row>
      
                                                                      <Row>
                                                                          <Col>
                                                                              <h6 className='f-12 float-end fw-bold color_red mt-2 text-decoration-underline' onClick={() => this.reloadMembershipCardScan()}>Re-Scan Membership Card <i class="fa-solid fa-rotate ms-1"></i></h6>
                                                                          </Col>
                                                                      </Row>
      
      
                                                                      <Row>
                                                                          <Col>
                                                                              <Button variant="outline-success" className='f-16 center-auto fw-bold mt-3' disabled={this.state.saveCardDistributionDisabled} onClick={() => this.saveCardDistribution()}>SUBMIT
                                                                                  {
                                                                                      this.state.saveCardDistributionLoadingSymbol == "show" ? (<Image src={loader} alt="loader" className='loader_cls ms-2'></Image>) : ("")
                                                                                  }
                                                                              </Button>
                                                                          </Col>
                                                                      </Row>
                                                                      
                                                                      {
                                                                          this.state.saveCardDistributionSuccessMsg !="" ?
                                                                          ( 
                                                                              <>
                                                                              <Row>
                                                                                  <Col>
                                                                                      <h6 className='text-center fw-bold mt-2'>{this.state.saveCardDistributionSuccessMsg}</h6>
                                                                                  </Col>
                                                                              </Row>
      
                                                                              <Row>
                                                                                  <Col>
                                                                                      <Button variant="outline-danger" className='f-16 center-auto fw-bold  mt-3' onClick={() => this.reloadMembershipCardScan()}>SCAN NEW MEMBERSHIP</Button>
                                                                                  </Col>
                                                                              </Row>
      
                                                                              </>
                                                                          )
                                                                          :
                                                                          ("")
                                                                      }
      
      
                                                                      </>
                                                                  )
                                                                  :
                                                                  (<>
                                                                      {
                                                                      this.state.membershipScannDataCheck == 1 ?
                                                                      (<h6 className='f-14 text-center mt-4'>No Data Available...</h6>)
                                                                      :
                                                                      ("")
                                                                  }
                                                                  </>)
                                                              }
                                                              </>
      
      
                                                              
                                                          )
                                                          :
                                                          (
                                                          "" 
                                                          )
                                                      }
                                                  </>
                                                    )
                                                  }
                                            </>

                                           
                                        )
                                    }
                                </>
                             )
                             :
                             (
                                <>
                                    {
                                        this.state.locationStatus == "The request to get user location timed out,Please Close The BOT Then Reopen..." ?
                                        (
                                            <>
                                            <h6 className='text-center color_red mt-3'>{this.state.locationStatus}</h6>
                    
                                            <h6 className='text-center color_red mt-2'>(or)</h6>
                    
                                            <Button type="button" variant="outline-danger" className='fw-bold f-14 center-auto mt-3' onClick={(e) => this.reloadCDSPage(e)}>RELOAD</Button>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                            <Row>
                                                <Col sm={12}>
                                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                                </Col>
                                            </Row>
                    
                                            <h6 className='text-center color_red mt-3'>{this.state.locationStatus}</h6>
                                        </>
                                        )
                                        }
                                    </>
                             )
                        }
                        

                        
                       <CapturePhotoForScannedMembership
                        show={this.state.capturePhotoModal}
                        onHide={() => this.setState({ capturePhotoModal: false })}
                        stateData={this.state}
                        uploadMultipleFilesForPhotosNew={this.uploadMultipleFilesForPhotosNew}
                        uploadImageForMembership={this.uploadImageForMembership}
                        captureForActivityImageUploadNew={this.captureForActivityImageUploadNew}
                        selectCaptureTypeNew={this.selectCaptureTypeNew}
                />

                    </Container>
                </div>
        </section>

    );
  }
}

const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#000",
    color: "#fff",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "22px",
    marginBottom: "15px",
  },
  scannerContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "400px",
    height: "300px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scannerFrame: {
    width: "250px",
    height: "250px",
    position: "relative",
    overflow: "hidden",
  },
  resultText: {
    marginTop: "20px",
    fontSize: "16px",
    color: "#fff",
  },
};

// Add CSS for scanner border corners and animation
const stylesCSS = `
  .corner {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
  }
  .top-left {
    top: 0;
    left: 0;
    border-top-color: red;
    border-left-color: red;
  }
  .top-right {
    top: 0;
    right: 0;
    border-top-color: blue;
    border-right-color: blue;
  }
  .bottom-left {
    bottom: 0;
    left: 0;
    border-bottom-color: green;
    border-left-color: green;
  }
  .bottom-right {
    bottom: 0;
    right: 0;
    border-bottom-color: yellow;
    border-right-color: yellow;
  }
  .scan-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: red;
    animation: scanAnimation 2s infinite;
  }
  @keyframes scanAnimation {
    0% { top: 0; }
    50% { top: 100%; }
    100% { top: 0; }
  }
`;

document.head.insertAdjacentHTML("beforeend", `<style>${stylesCSS}</style>`);

export default withRouter(CardDistributionSection);
