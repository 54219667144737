import React, { Component } from "react";
import {Container, Row, Col,Image,Button,Form,Card } from 'react-bootstrap';
import "../CadreRegistration/cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import "../EventsAndActivities/EventsAndActivities.css";
import { getAPICallDataForCadre } from '../../services/MemberService';

class CadreSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAccessList:props?.location?.params?.userAccessList == undefined ? "" : props?.location?.params?.userAccessList,
      constituencyId:"",
      searchTypeName:"membershipId",
      placeholderName:"Enter Membership Id",
      maxLengthvalue:"8",
      searchTypeValue:"",
      memberDtsArr:[],
      memberDtsCheck:0,
      memberDtsLoadingSymnbol:"hide",
      validateSearchValue:"",
      cadreSerachBlockShow:false
      
    };
    
    
  }

  componentDidMount() {
    if(this.state.userAccessList == "" || this.state.campaignId == ""){
      this.props.history.push("/dashboard");
  }else{
        this.setState({constituencyId:this.state.userAccessList.constituencyList[0].id});
  }
}


checkCadreSearchType = (type) => {
    this.setState({validateSearchValue:"",cadreSerachBlockShow:false,searchTypeValue:"" });
    this.setState({searchTypeName:type})
    if(type =='membershipId'){
        this.setState({placeholderName:"Enter Membership Id",maxLengthvalue:"8"})
    }else if(type =='mobileNoId'){
        this.setState({placeholderName:"Enter Mobile No",maxLengthvalue:"10"})
    }else{
        this.setState({placeholderName:"Enter Voter Card No",maxLengthvalue:""})
    }
    
}

handleOnchangeInputBox = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  }


getCadreSearchDetailsAction = () => {
    this.setState({validateSearchValue:"" });

    if(this.state.searchTypeName == "membershipId"){
        if(this.state.searchTypeValue == ""){
            this.setState({validateSearchValue:"Please Enter Membership"});
            return;
        }
        if(this.state.searchTypeValue.length < 8){
            this.setState({
                validateSearchValue: "Please Enter 8 Digits Valid Membership Id"
            });
            return;
        }
    }else if(this.state.searchTypeName == "mobileNoId"){
        var expr = /^(0|91)?[6-9][0-9]{9}$/;

            if(this.state.searchTypeValue == ""){
                this.setState({validateSearchValue:"Please Enter Mobile No" });
                return;
            }

            if(!expr.test(this.state.searchTypeValue)){
                this.setState({validateSearchValue:"Please Enter Valid Mobile No" });
                return;
            }

            if(this.state.searchTypeValue.length < 10){
                this.setState({validateSearchValue:"Please Enter Valid Mobile No" });
                return;
            }
    }else{
        if(this.state.searchTypeValue == ""){
            this.setState({validateSearchValue:"Please Enter Voter Card No"});
            return;
        }
    }
    

    this.setState({memberDtsLoadingSymnbol:"show",memberDtsArr:[],memberDtsCheck:0,cadreSerachBlockShow:true})

    let userAccessObj;
    if(this.state.searchTypeName == "membershipId"){
        userAccessObj =  
        JSON.stringify({ 
            "mid" : this.state.searchTypeValue,
            "mobileNo" : "",
            "vno" : "",
            "constituencyId" : this.state.constituencyId,
            "username" : "app_api_cadre",
            "password" : "6Da3!p#w$5xqinRk"
        });
    }else if(this.state.searchTypeName == "mobileNoId"){
        userAccessObj =  
        JSON.stringify({ 
            "mid" : "",
            "mobileNo" : this.state.searchTypeValue,
            "vno" : "",
            "constituencyId" : this.state.constituencyId,
            "username" : "app_api_cadre",
            "password" : "6Da3!p#w$5xqinRk"
        });
    }else{
        userAccessObj =  
        JSON.stringify({ 
            "mid" : "",
            "mobileNo" : "",
            "vno" : this.state.searchTypeValue,
            "constituencyId" : this.state.constituencyId,
            "username" : "app_api_cadre",
            "password" : "6Da3!p#w$5xqinRk"
        });
    }
    

        getAPICallDataForCadre(userAccessObj,"getCitVlorSearchDetails")
        .then((res) => res.json())
        .then((content) => {
            this.setState({memberDtsLoadingSymnbol:"hide"})
            if(content !=null && content.length>0){
                //const obj = Object.assign({}, ...content);
                this.setState({memberDtsArr:content,memberDtsCheck:0})
            }else{
                this.setState({memberDtsArr:[],memberDtsCheck:1})
            }
        });
}

  


  render() {
    
    
    return (
      <section className="mb-4">
        <div className='p-1'>
            <Container>
                <Row>
                    <Col>
                        <div className='bor-bottom-yellow-pad'>
                            <h5 className='text-center color_red text-uppercase'>Cadre Search</h5>
                        </div>
                    </Col>
                </Row>

                <Row>
                <Col sm={12} className="mt-3">
                    <h6 className='f-14 fw-bold mt-2'>Search By :</h6>
                    <div className='border_pad mt-2'>
                        <Row>
                            <Col>
                            <Form >
                                <Form.Check
                                    inline
                                    label="Membership Id"
                                    name="cadreSearchName"
                                    type="radio"
                                    id="membershipId"
                                    onChange={()=>this.checkCadreSearchType("membershipId")}
                                    className="f-12 fw-bold"
                                    value="membershipId"
                                    defaultChecked={true}
                                    
                                />
                                <Form.Check
                                    inline
                                    label="Mobile No"
                                    name="cadreSearchName"
                                    type="radio"
                                    id="mobileNoId"
                                    onChange={()=>this.checkCadreSearchType("mobileNoId")}
                                    className="f-12 fw-bold"
                                    value="mobileNoId"
                                />
                                <Form.Check
                                    inline
                                    label="Voter Id"
                                    name="cadreSearchName"
                                    type="radio"
                                    id="voterId"
                                    onChange={()=>this.checkCadreSearchType("voterId")}
                                    className="f-12 fw-bold"
                                    value="voterId"
                                />
                                
                            </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-2">
                                <input type="text" name="searchTypeValue" className="form-control from-control-sm" placeholder={this.state.placeholderName} maxLength={this.state.maxLengthvalue} onChange={(e) => this.handleOnchangeInputBox(e)}/>
                                {
                                    this.state.validateSearchValue != "" ?
                                    (<span className="error_cls_new f-12">{this.state.validateSearchValue}</span>)
                                    :
                                    ("")
                                }
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button type="button" className='btn btn-sm btn-success center-auto mt-3 fw-bold' onClick={(e) => this.getCadreSearchDetailsAction(e)}>SUBMIT</Button>
                </Col>
            </Row>

            {
                this.state.cadreSerachBlockShow == true ?
                (
                    <>
                    {
                        this.state.memberDtsLoadingSymnbol == "show" && this.state.memberDtsArr.length == 0 ?
                        (
                             <Row>
                                <Col sm={12}>
                                    <div class="spinner-border color_yellow loading-img-center mt-4"></div>
                                </Col>
                            </Row>
                        )
                        :
                        ("")
                    }

                    {
                        this.state.memberDtsArr.length > 0 ?
                        (
                                <>
                                {
                                    this.state.memberDtsArr.map(item => {
                                        return(
                                            <Row>
                                            <Col>
                                                <div className="border_yash_pad mt-2">
                                                    <div className="media">
                                                        <Image variant="top" src={item.imageUrl} className='img-thumbnail mr-3 width_height_cadre_img' />
                                                        <div className="media-body">
                                                            <h6 className='f-12'>పేరు: {item.cname}</h6>
                                                            <h6 className='f-12'>సభ్యత్వం నెంబర్  : {item.mid}</h6>
                                                            <h6 className='f-12'>మొబైల్  నెంబర్  : {item.mobileNo}</h6>
                                                        </div>
                                                    </div>
                                                    <h6 className='f-12 mt-2'>ఓటరు నెంబర్ : {item.vno}</h6>
                                                    <h6 className='f-12 mt-2'>బంధువు పేరు  : {item.relativeName} |  సంబంధం  : {item.relation}</h6>
                                                    <h6 className='f-12 mt-2'>ఇంటి నెంబర్ :{item.houseNo}  |  లింగం : {item.gender}  |  వయస్సు :{item.age}</h6>
                                                    <h6 className='f-12 mt-2'>మండల్ / టౌన్ :{item.mandalName == "" ? item.townName : item.mandalName} </h6>
                                                    {
                                                        item.mandalName != "" ?
                                                        (
                                                            <h6 className='f-12 mt-2'>పంచాయత్ :{item.villageName} </h6>
                                                        )
                                                        :
                                                        ("")
                                                    }
                                                    
                                                        
                                                        
                                                </div>
                                                
                                            </Col>
                                            </Row>
                                        )
                                    })
                                }
                                </>
                                
                            
                        )
                        :
                        (
                            <>
                                {
                                this.state.memberDtsCheck == 1 ?
                                (<h6 className='f-14 text-center mt-4'>No Data Available...</h6>)
                                :
                                ("")
                            }
                            </>
                        )
                    }

                    </>
                   
                )
                :
                ("")
            }


            </Container>
        </div>
     </section>

    );
  }
}

export default withRouter(CadreSearch);
