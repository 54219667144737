
import React ,{ Component } from 'react';
import {Container, Row, Col,Image,Button,Form,Card,InputGroup,Collapse  } from 'react-bootstrap';
import { withRouter,Link } from "react-router-dom";
import loader from '../../images/loader.gif';
import "../CadreRegistration/cadreRegistration.css";
import InnerBoxScannerModal from './InnerBoxScannerModal';
import { Html5Qrcode, Html5QrcodeScanner } from 'html5-qrcode';

class CardScannerSection extends Component {
    constructor(props) {
        super(props)
        

        this.state = {
           
            innerBoxInputVal:"",
            validateInnerBoxVal:"",
            innerBoxScannerModal:false,

            boxDetailsObj:{},
            errorMessageMembershipCard:"",

             backCameraMembershipId: null,
             scannedResultForMembership:"",

             outerBoxObj:{}
             
            
        };

        this.qrScannerForMembership = null;
        
    }

    componentDidMount() {
       
    }

    componentWillUnmount() {
        if (this.qrScannerForMembership) {
          this.qrScannerForMembership.clear();
        }
      }

updateinnerBoxInputVal = (boxValue) => {
    this.setState({ innerBoxInputVal: boxValue });

    this.setState({innerBoxScannerModal:false})
};
    
handleOnchangeInput = (e,type) => {
    const name = e.target.name;
    const value = e.target.value;
  
    this.setState({ [name]: value });
  }

  getInnerBoxDetails = () => {
    this.setState({boxDetailsObj:{},errorMessageForInnerBox:""})
    if(this.state.innerBoxInputVal == ""){
        this.setState({validateInnerBoxVal:"Please Enter Box Id/Scan"});
        return;
    }

    this.getInnerBox_Details('https://e95ba1034c28.ngrok.app/membership/mobile.php?getInnerBox_Details=1&box_id='+this.state.innerBoxInputVal+'');

  }


  
getInnerBox_Details = async (url) => {
	try {
	  // Make the fetch request

    const response = await fetch(url);
	  
	  // Check if the response is successful
	  if (!response.ok) {
		throw new Error('Network response was not ok');
	  }
  
	  // Parse the response body as JSON
	  const data = await response.json();
  
	  // Do something with the data
	  console.log(data);
	if(data !=null && data.result == "success"){
		this.setState({boxDetailsObj:data});
        
        setTimeout(() => {
            this.getBackCameraForMembership();
        }, 1000);
		
	}else if(data !=null && data.result == "failed"){
        this.setState({errorMessageForInnerBox:data.error})
	}else{
        this.setState({errorMessageForInnerBox:"In Valid Box Id"})
	}

	  
	} catch (error) {
		this.setState({errorMessageForInnerBox:"In Valid Box Id"})
	  console.error('There was a problem with the fetch operation:', error);
	}
  }


  getQRScanCodeModal = () => {
    this.setState({innerBoxScannerModal:true})
    
  }


   getBackCameraForMembership = () => {
          Html5Qrcode.getCameras()
            .then((cameras) => {
              if (cameras && cameras.length > 0) {
                let backCamera = cameras.find((cam) => cam.label.toLowerCase().includes("back"));

                const cameraId = backCamera ? backCamera.id : cameras[0].id; // Default to first camera if no back camera found
  
               
  
                setTimeout(() => {
                  this.setState({errorMessageMembershipCard:""});
                  this.setState({ backCameraMembershipId: cameraId }, this.initializeQRScannerForMembership);
                }, 1000);
                
                
              }
            })
            .catch((err) => console.error('Error getting cameras:', err));
        };
      
        initializeQRScannerForMembership = () => {
          if (!this.state.backCameraMembershipId) return;
          
          this.qrScannerForMembership = new Html5QrcodeScanner(
            'qr-scanner-membership',
            {
              fps: 30,
              aspectRatio: 1.0,
              qrbox: { width: 250, height: 250 },
              disableFlip: true,
              //supportedScanTypes: [Html5QrcodeScanner.SCAN_TYPE_CAMERA],
              rememberLastUsedCamera: true,
             // videoConstraints: { facingMode: { exact: "environment" } }
            },
            false
          );
      
          
          this.qrScannerForMembership.render(
            (decodedText) => {
              if (decodedText !== this.state.scannedResultForMembership) {
                this.setState({ scannedResultForMembership: decodedText });
                console.log('QR Code detected:', decodedText);

                var membershipNoVal="";
                var splitMemberShip = "";
                var url = decodedText

                splitMemberShip = url.split("=")

                membershipNoVal = splitMemberShip[1];

                console.log('jjjj',membershipNoVal)

                if(membershipNoVal != ""){
                    if(membershipNoVal.length == 8){
                        console.log(membershipNoVal)
                        if(membershipNoVal != "https://"){
                          
                          console.log("yyyyyyyyyyyyyyyyyy")
                           this.updatedinnerbox('https://e95ba1034c28.ngrok.app/membership/mobile.php?update_innerbox=1&box_id='+this.state.boxDetailsObj.box_id+'&membership_id='+membershipNoVal+'&username=scan6');
                        }
                        
                    }
                }

              }else{
                this.setState({errorMessageMembershipCard:"Already This Card Scanned"});
                setTimeout(() => {
                  this.setState({errorMessageMembershipCard:""});
              }, 1000);
              }

              this.qrScannerForMembership.pause();
              setTimeout(() => {
                this.qrScannerForMembership.resume();
              }, 1500); 

            },
            (errorMessage) => {
              //console.error("QR Scan error:", errorMessage);
              // Suppress minor errors for smooth scanning
            }
          );
        };

    
        
 updatedinnerbox  = async(url) => {
  this.setState({errorMessageMembershipCard:"",outerBoxObj:{}});
	try {
	  // Make the fetch request
	  const response = await fetch(url);
	  
	  // Check if the response is successful
	  if (!response.ok) {
		throw new Error('Network response was not ok');
	  }
  
	  // Parse the response body as JSON
	  const data = await response.json();
    this.setState({outerBoxObj:data})
	  // Do something with the data
	  if(data !=null && data.result == "success"){
        this.setState({errorMessageMembershipCard:"Updated"});
        this.setState((prevState) => ({
          boxDetailsObj: { ...prevState.boxDetailsObj, loaded: data.loaded, yet_to_load: data.yet_to_load , rejected : data.rejected }
        }));
          setTimeout(() => {
            this.setState({errorMessageMembershipCard:""});
        }, 1000);
		
		
	}else if(data !=null && data.result == "failed"){
        this.setState({errorMessageMembershipCard:data.error})
        setTimeout(() => {
          this.setState({errorMessageMembershipCard:""});
      }, 1000);
		
	}else{
        this.setState({errorMessageMembershipCard:"In valid"})
        setTimeout(() => {
          this.setState({errorMessageMembershipCard:""});
      }, 1000);
	}

	  
	} catch (error) {
		this.setState({errorMessageMembershipCard:"In valid"})
	  console.error('There was a problem with the fetch operation:', error);
	}
  }

  
    render() {
     

        return (
            <section className="mb-4">
                <div className='p-1'>
                    <Container>
                        <Row>
                            <Col>
                                <div className='bor-bottom-yellow-pad'>
                                    <h5 className='text-center color_red mt-2 text-uppercase'>Card Print Scanning</h5>
                                </div>
                                <Link to={{pathname: "/dashboard"}}> 
                                    <Button variant="outline-danger pad5_14" className='f-12  fw-bold float-end'> <i class="fa-solid fa-house"></i> Home</Button> 
                                </Link>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <label className="form-label f-14 fw-bold">Enter BOX ID / Scan <span className='errorCls'>{this.state.validateInnerBoxVal}</span> <span className="fa-solid fa-qrcode float-end f-20 ms-2 scanner_css_cs" onClick={() => this.getQRScanCodeModal()}></span></label>
                                <input type="text" className='form-control' name="innerBoxInputVal" value={this.state.innerBoxInputVal}  placeholder='Enter BOX ID / Scan'   onChange={(e) => this.handleOnchangeInput(e,"innerBoxVal")}></input>
                                
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Button variant="outline-dark pad5_14 mt-3 " className='f-12 fw-bold center-auto' onClick={() => this.getInnerBoxDetails()}> GET BOX DETAILS</Button> 
                            </Col>
                        </Row>

                        {
                            this.state.errorMessageForInnerBox != "" ?
                            (
                                <Row>
                                    <Col>
                                        <h5 className='errorCls'>{this.state.errorMessageForInnerBox}</h5>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                             

                        }
                        {
                            this.state.innerBoxScannerModal == true ?
                            (
                                <InnerBoxScannerModal
                                    show={this.state.innerBoxScannerModal}
                                    onHide={() => this.setState({ innerBoxScannerModal: false })}
                                    stateData = {this.state}
                                    updateParentStateInnerBox={this.updateinnerBoxInputVal}
                                />
                            )
                            :
                            ("")
                        }
                        

                        {
                           Object.keys(this.state.boxDetailsObj).length > 0 ?
                           (
                            <>
                           

                            <div className='box_css mt-3'>
                                <Row>
                                    <Col>
                                    <div id="qr-scanner-membership" ></div>
                                       
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                          this.state.errorMessageMembershipCard == "Updated" ?
                                          (<h5 className='color_green'>{this.state.errorMessageMembershipCard}</h5>)
                                          :
                                          (<h5 className='color_red'>{this.state.errorMessageMembershipCard}</h5>)
                                        }
                                        
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='mt-3'>
                                        <h6 class="color_black f-13">Box ID : </h6>
                                        <h6 class="color_black f-13">Inner Box No : </h6>
                                        <h6 class="color_black f-13">Loaded Cards : </h6>
                                        <h6 class="color_black f-13">Rejected Cards : </h6>
                                        <h6 class="color_black f-13">Yet To Load : </h6>
                                        <h6 class="color_black f-13">Booth No : </h6>
                                        <h6 class="color_black f-13">Constituency : </h6>
                                        <h6 class="color_black f-13">District : </h6>
                                    </Col>
                                    <Col className='mt-3'>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.box_id}</h6>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.box_name}</h6>
                                    <h6 class="text-center color_black f-13"><span id="loadedCountId">{this.state.boxDetailsObj.loaded}</span></h6>
                                    <h6 class="text-center color_black f-13"><span id="rejectedloadedCountId">{this.state.boxDetailsObj.rejected}</span></h6>
                                    <h6 class="text-center color_black f-13"><span id="yetToloadedCountId">{this.state.boxDetailsObj.yet_to_load}</span></h6>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.booth_no}</h6>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.constituency}</h6>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.district}</h6>
                                    </Col>
                                </Row>
                                 
                                
                            </div>

                            </>
                            
                           )
                           :
                           ("")
                        }


                    </Container>
                </div>
        </section>
        )
    }
  }
  
  export default withRouter(CardScannerSection);
  