import React, { Component } from "react";
import {Container, Row, Col,Image,Button,Form,Card,InputGroup,Collapse  } from 'react-bootstrap';
import "../CadreRegistration/cadreRegistration.css";
import { withRouter,Link } from "react-router-dom";
import InnerBoxScannerModal from './InnerBoxScannerModal';
import { BrowserQRCodeReader } from "@zxing/library";

class CheckingScannerSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrResult: "",
      scanning: true,
      boxDetailsObj:{},
      errorMessageForInnerBox:"",
      innerBoxInputVal:"",
      validateInnerBoxVal:"",
      errorMessageMembershipCard:"",
      innerBoxScannerModal:false,
      membershipNoTextUpdated:""
    };
    this.videoRef = React.createRef();
    this.codeReader = new BrowserQRCodeReader();
    
  }

  componentDidMount() {
   
  }

  componentWillUnmount() {
    this.stopCamera();
  }
  
  getQRScanCodeModalNew = () => {
    this.setState({innerBoxScannerModal:true})
    
  }

  updateinnerBoxInputVal = (boxValue) => {
    this.setState({ innerBoxInputVal: boxValue });

    this.setState({innerBoxScannerModal:false})
};

  handleOnchangeInput = (e,type) => {
    const name = e.target.name;
    const value = e.target.value;
  
    this.setState({ [name]: value });
  }

  getInnerBoxDetails = () => {
    this.setState({boxDetailsObj:{},errorMessageForInnerBox:""})
    if(this.state.innerBoxInputVal == ""){
        this.setState({validateInnerBoxVal:"Please Enter Box Id/Scan"});
        return;
    }

    this.getInnerBox_Details('https://e95ba1034c28.ngrok.app/membership/mobile.php?getInnerBox_Details=1&box_id='+this.state.innerBoxInputVal+'');

  }


  
getInnerBox_Details = async (url) => {
	try {
	  // Make the fetch request

    const response = await fetch(url);
	  
	  // Check if the response is successful
	  if (!response.ok) {
		throw new Error('Network response was not ok');
	  }
  
	  // Parse the response body as JSON
	  const data = await response.json();
  
	  // Do something with the data
	  console.log(data);
	if(data !=null && data.result == "success"){
		this.setState({boxDetailsObj:data});
        
        setTimeout(() => {
          this.startCamera();
        }, 500);
		
	}else if(data !=null && data.result == "failed"){
        this.setState({errorMessageForInnerBox:data.error})
	}else{
        this.setState({errorMessageForInnerBox:"In Valid Box Id"})
	}

	  
	} catch (error) {
		this.setState({errorMessageForInnerBox:"In Valid Box Id"})
	  console.error('There was a problem with the fetch operation:', error);
	}
  }

  stopCamera = () => {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
    }
    this.codeReader.reset();
  };

  startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        const video = this.videoRef.current;
        video.srcObject = stream;
        video.setAttribute("playsinline", true); // For iOS
        video.play();
        this.scanQRCodeMainBox();
      })
      .catch((err) => console.error("Camera Access Error:", err));
  };

  scanQRCodeMainBox = () => {
    this.codeReader.decodeFromVideoDevice(
      undefined,
      this.videoRef.current,
      (result, err) => {
        if (result) {
          this.setState({ qrResult: result.text,scanning: false });
          console.log('QR Code detected:', result.text);
         
          var membershipNoVal="";
          var splitMemberShip = "";
          var url = result.text

          splitMemberShip = url.split("=")

          membershipNoVal = splitMemberShip[1];

          console.log('jjjj',membershipNoVal)

          if(membershipNoVal != ""){
              if(membershipNoVal.length == 8){
                  console.log(membershipNoVal)
                  if(membershipNoVal != "https://"){
                    this.setState({membershipNoTextUpdated:membershipNoVal})
                    console.log("yyyyyyyyyyyyyyyyyy")
                    setTimeout(() => {
                      this.updatedinnerbox('https://e95ba1034c28.ngrok.app/membership/mobile.php?update_innerbox=1&box_id='+this.state.boxDetailsObj.box_id+'&membership_id='+membershipNoVal+'&username=scan6');
                   }, 800);
                     
                  }
                  
              }
          }

          
        }
      }
    );
  };

         
 updatedinnerbox  = async(url) => {
  this.setState({errorMessageMembershipCard:""});
	try {
	  // Make the fetch request
	  const response = await fetch(url);
	  
	  // Check if the response is successful
	  if (!response.ok) {
		throw new Error('Network response was not ok');
	  }
  
	  // Parse the response body as JSON
	  const data = await response.json();
    this.setState({outerBoxObj:data})
    

	  // Do something with the data
	  if(data !=null && data.result == "success"){
        this.setState({errorMessageMembershipCard:"Updated"});
        this.setState((prevState) => ({
          boxDetailsObj: { ...prevState.boxDetailsObj, loaded: data.loaded, yet_to_load: data.yet_to_load , rejected : data.rejected }
        }));
       

        setTimeout(() => {
          this.setState({errorMessageMembershipCard:""});
          this.setState({ qrResult: "", scanning: true });
          this.scanQRCode(); // Restart scanning
        }, 2000);
		
		
	}else if(data !=null && data.result == "failed"){
    this.setState({errorMessageMembershipCard:data.error});
        setTimeout(() => {
            this.setState({errorMessageMembershipCard:""});
            this.setState({ qrResult: "", scanning: true });
            this.scanQRCode(); // Restart scanning
          }, 2000);
		
	}else{
    this.setState({errorMessageMembershipCard:"In valid"})
      setTimeout(() => {
        this.setState({errorMessageMembershipCard:""})
        this.setState({ qrResult: "", scanning: true });
        this.scanQRCode(); // Restart scanning
      }, 2000);

       
	}

	  
	} catch (error) {
     this.setState({errorMessageMembershipCard:"In valid"})
    setTimeout(() => {
      this.setState({errorMessageMembershipCard:""})
      this.setState({ qrResult: "", scanning: true });
      this.scanQRCode(); // Restart scanning
    }, 1500);

	  console.error('There was a problem with the fetch operation:', error);
	}
  }

  render() {
    return (
      <section className="mb-4">
                <div className='p-1'>
                    <Container>
                        <Row>
                            <Col>
                                <div className='bor-bottom-yellow-pad'>
                                    <h5 className='text-center color_red mt-2 text-uppercase'>Card Print Scanning</h5>
                                </div>
                                <Link to={{pathname: "/dashboard"}}> 
                                    <Button variant="outline-danger pad5_14" className='f-12  fw-bold float-end'> <i class="fa-solid fa-house"></i> Home</Button> 
                                </Link>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <label className="form-label f-14 fw-bold">Enter BOX ID / Scan <span className='errorCls'>{this.state.validateInnerBoxVal}</span> <span className="fa-solid fa-qrcode float-end f-20 ms-2 scanner_css_cs" onClick={() => this.getQRScanCodeModalNew()}></span></label>
                                <input type="text" className='form-control' name="innerBoxInputVal" value={this.state.innerBoxInputVal}  placeholder='Enter BOX ID / Scan'   onChange={(e) => this.handleOnchangeInput(e,"innerBoxVal")}></input>
                                
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Button variant="outline-dark pad5_14 mt-3 " className='f-12 fw-bold center-auto' onClick={() => this.getInnerBoxDetails()}> GET BOX DETAILS</Button> 
                            </Col>
                        </Row>



                        {
                            this.state.errorMessageForInnerBox != "" ?
                            (
                                <Row>
                                    <Col>
                                        <h5 className='errorCls'>{this.state.errorMessageForInnerBox}</h5>
                                    </Col>
                                </Row>
                            )
                            :
                            ("")
                             

                        }
                        

                        {
                            this.state.innerBoxScannerModal == true ?
                            (
                                <InnerBoxScannerModal
                                    show={this.state.innerBoxScannerModal}
                                    onHide={() => this.setState({ innerBoxScannerModal: false })}
                                    stateData = {this.state}
                                    updateParentStateInnerBox={this.updateinnerBoxInputVal}
                                />
                            )
                            :
                            ("")
                        }
                        

                        {
                           Object.keys(this.state.boxDetailsObj).length > 0 ?
                           (
                            <>
                           

                            <div className='box_css mt-3'>
                                <Row>
                                    <Col>
                                    <div style={styles.scannerContainer}>
                                      <video ref={this.videoRef} style={styles.video} />
                                      <div style={styles.overlay}>
                                        <div style={styles.scannerFrame}>
                                          {/* Scanner Border Corners */}
                                          <div className="corner top-left"></div>
                                          <div className="corner top-right"></div>
                                          <div className="corner bottom-left"></div>
                                          <div className="corner bottom-right"></div>
                                         
                                        </div>
                                      </div>
                                    </div>
                                       
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                          this.state.errorMessageMembershipCard == "Updated" ?
                                          (<h5 className='color_green'>{this.state.errorMessageMembershipCard}</h5>)
                                          :
                                          (<h5 className='color_red'>{this.state.errorMessageMembershipCard}</h5>)
                                        }
                                        
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='mt-3'>
                                        <h6 class="color_black f-13">Box ID : </h6>
                                        <h6 class="color_black f-13">Inner Box No : </h6>
                                        <h6 class="color_black f-13">Loaded Cards : </h6>
                                        <h6 class="color_black f-13">Rejected Cards : </h6>
                                        <h6 class="color_black f-13">Yet To Load : </h6>
                                        <h6 class="color_black f-13">Booth No : </h6>
                                        <h6 class="color_black f-13">Constituency : </h6>
                                        <h6 class="color_black f-13">District : </h6>
                                    </Col>
                                    <Col className='mt-3'>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.box_id}</h6>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.box_name}</h6>
                                    <h6 class="text-center color_black f-13"><span id="loadedCountId">{this.state.boxDetailsObj.loaded}</span></h6>
                                    <h6 class="text-center color_black f-13"><span id="rejectedloadedCountId">{this.state.boxDetailsObj.rejected}</span></h6>
                                    <h6 class="text-center color_black f-13"><span id="yetToloadedCountId">{this.state.boxDetailsObj.yet_to_load}</span></h6>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.booth_no}</h6>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.constituency}</h6>
                                    <h6 class="text-center color_black f-13">{this.state.boxDetailsObj.district}</h6>
                                    </Col>
                                </Row>
                                 
                                
                            </div>

                            </>
                            
                           )
                           :
                           ("")
                        }


                    </Container>
                </div>
        </section>

    );
  }
}

const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#000",
    color: "#fff",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "22px",
    marginBottom: "15px",
  },
  scannerContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "400px",
    height: "300px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scannerFrame: {
    width: "250px",
    height: "250px",
    position: "relative",
    overflow: "hidden",
  },
  resultText: {
    marginTop: "20px",
    fontSize: "16px",
    color: "#fff",
  },
};

// Add CSS for scanner border corners and animation
const stylesCSS = `
  .corner {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
  }
  .top-left {
    top: 0;
    left: 0;
    border-top-color: red;
    border-left-color: red;
  }
  .top-right {
    top: 0;
    right: 0;
    border-top-color: blue;
    border-right-color: blue;
  }
  .bottom-left {
    bottom: 0;
    left: 0;
    border-bottom-color: green;
    border-left-color: green;
  }
  .bottom-right {
    bottom: 0;
    right: 0;
    border-bottom-color: yellow;
    border-right-color: yellow;
  }
  .scan-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: red;
    animation: scanAnimation 2s infinite;
  }
  @keyframes scanAnimation {
    0% { top: 0; }
    50% { top: 100%; }
    100% { top: 0; }
  }
`;

document.head.insertAdjacentHTML("beforeend", `<style>${stylesCSS}</style>`);

export default withRouter(CheckingScannerSection);
