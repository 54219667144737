import React, { Component } from 'react';
import { Html5Qrcode, Html5QrcodeScanner } from 'html5-qrcode';
import { withRouter,Link } from "react-router-dom";
class QrReaderCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scannedResult: '',
      backCameraId: null,
    };
    this.qrScanner = null;
  }

  componentDidMount() {
    this.getBackCamera();
  }

  componentWillUnmount() {
    if (this.qrScanner) {
      this.qrScanner.clear();
    }
  }

  getBackCamera = () => {
    Html5Qrcode.getCameras()
      .then((cameras) => {
        if (cameras && cameras.length > 0) {
          // Find the back camera (usually contains "back" in the label)
          const backCamera = cameras.find((camera) =>
            camera.label.toLowerCase().includes('back')
          );

          const cameraId = backCamera ? backCamera.id : cameras[0].id; // Default to first camera if no back camera found
          this.setState({ backCameraId: cameraId }, this.initializeQRScanner);
        }
      })
      .catch((err) => console.error('Error getting cameras:', err));
  };

  initializeQRScanner = () => {
    if (!this.state.backCameraId) return;

    this.qrScanner = new Html5QrcodeScanner(
      'qr-scanner',
      {
        fps: 15,
        qrbox: { width: 250, height: 250 },
        //supportedScanTypes: [Html5QrcodeScanner.SCAN_TYPE_CAMERA],
        rememberLastUsedCamera: true,
      },
      false
    );

    this.qrScanner.render(
      (decodedText) => {
        if (decodedText !== this.state.scannedResult) {
          this.setState({ scannedResult: decodedText });
          console.log('QR Code detected:', decodedText);
        }
      },
      (errorMessage) => {
        // Suppress minor errors for smooth scanning
      }
    );
  };

  render() {
    return (
      <div>
        <h2>QR Code Scanner (Back Camera by Default)</h2>
        <div id="qr-scanner"></div>
        {this.state.scannedResult && (
          <p><strong>Scanned Result:</strong> {this.state.scannedResult}</p>
        )}
      </div>
    );
  }
}

export default withRouter(QrReaderCode);
