import React, { Component } from 'react'
import { Modal, Button,Row,Col } from "react-bootstrap";
import { Html5Qrcode, Html5QrcodeScanner } from 'html5-qrcode';

class InnerBoxScannerModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            backCameraId: null,
            scannedResult:props?.stateData.innerBoxInputVal
        }
        this.qrScanner = null;
      
    }

    componentDidMount() {
        if(this.props.stateData.innerBoxScannerModal == true){
            setTimeout(() => {
                this.getBackCamera();
            }, 1000);
        }
        
        
    }

    componentWillUnmount() {
        if (this.qrScanner) {
          this.qrScanner.clear();
        }
      }

    getBackCamera = () => {
        Html5Qrcode.getCameras()
          .then((cameras) => {
            if (cameras && cameras.length > 0) {
              // Find the back camera (usually contains "back" in the label)
              const backCamera = cameras.find((camera) =>
                camera.label.toLowerCase().includes("back") || 
                camera.label.toLowerCase().includes("environment")
              );
    
              
              const cameraId = backCamera ? backCamera.id : cameras[0].id; // Default to first camera if no back camera found

             

              setTimeout(() => {
                this.setState({ backCameraId: cameraId }, this.initializeQRScanner);
              }, 1000);
              
            }
          })
          .catch((err) => console.error('Error getting cameras:', err));
      };
    
      initializeQRScanner = () => {
        if (!this.state.backCameraId) return;
        
        this.qrScanner = new Html5QrcodeScanner(
          'qr-scanner',
          {
            fps: 15,
            aspectRatio: 1.777,
            qrbox: { width: 250, height: 250 },
            //supportedScanTypes: [Html5QrcodeScanner.SCAN_TYPE_CAMERA],
            rememberLastUsedCamera: true,
          },
          false
        );
    
        
        this.qrScanner.render(
          (decodedText) => {
            if (decodedText !== this.state.scannedResult) {
              this.setState({ scannedResult: decodedText });
              console.log('QR Code detected:', decodedText);
              this.props.updateParentStateInnerBox(decodedText);
            }
          },
          (errorMessage) => {
            // Suppress minor errors for smooth scanning
          }
        );
      };
    
    
    render() {
       
      
        return (
            <Modal {...this.props} 
            size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Scan Your Box Scan Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                       <Row>
                            <Col>
                                <div>
                                    <div id="qr-scanner"></div>
                                </div>
                                {this.state.scannedResult && (
                                <p><strong>Scanned Result:</strong> {this.state.scannedResult}</p>
                                )}
                            </Col>
                        </Row>      
                </Modal.Body>
            </Modal>
            
        )
    }
}


export default InnerBoxScannerModal;
  

