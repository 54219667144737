import React, { Component } from 'react'
import { Modal, Button,Row,Col } from "react-bootstrap";
import { BrowserQRCodeReader } from "@zxing/library";

class InnerBoxScannerModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
          qrResult: "",
          scanning: true,
        }
        this.videoRefBox = React.createRef();
        this.codeReaderBox = new BrowserQRCodeReader();
      
    }

    componentDidMount() {
        if(this.props.stateData.innerBoxScannerModal == true){
          this.startCamera();
        }
        
        
    }

    startCamera = () => {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then((stream) => {
          const video = this.videoRefBox.current;
          video.srcObject = stream;
          video.setAttribute("playsinline", true);
          video.play();
          this.scanQRCode();
        })
        .catch((err) => console.error("Camera Access Error:", err));
    };
  
    scanQRCode = () => {
      this.codeReaderBox.decodeFromVideoDevice(
        undefined,
        this.videoRefBox.current,
        (result, err) => {
          if (result) {
            this.props.updateParentStateInnerBox(result.text);
          }
        }
      );
    };

    
    render() {
       
      
        return (
          <Modal {...this.props} 
                      size="md"  aria-labelledby="example-modal-sizes-title-lg" className="overView_video_play">
                <Modal.Header closeButton className='p-3'>
                    <Modal.Title className='f-14 fw-bold'>Scan Your Box Scan Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                        <Row>
                            <Col>
                              <div style={styles.scannerContainer}>
                                <video ref={this.videoRefBox} style={styles.video} />
                                <div style={styles.overlay}>
                                  <div style={styles.scannerFrame}>
                                    <div className="corner top-left"></div>
                                    <div className="corner top-right"></div>
                                    <div className="corner bottom-left"></div>
                                    <div className="corner bottom-right"></div>
                                    {this.state.scanning && <div className=""></div>}
                                  </div>
                                </div>
                              </div>
                            </Col>
                        </Row>      
                </Modal.Body>
            </Modal>
            
          
    );
  }
}

const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#000",
    color: "#fff",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "22px",
    marginBottom: "15px",
  },
  scannerContainer: {
    position: "relative",
    width: "100%",
    maxWidth: "400px",
    height: "300px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scannerFrame: {
    width: "250px",
    height: "250px",
    position: "relative",
    overflow: "hidden",
  },
  resultText: {
    marginTop: "20px",
    fontSize: "16px",
    color: "#fff",
  },
};

// Add CSS for scanner border corners and animation
const stylesCSS = `
  .corner {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
  }
  .top-left {
    top: 0;
    left: 0;
    border-top-color: red;
    border-left-color: red;
  }
  .top-right {
    top: 0;
    right: 0;
    border-top-color: blue;
    border-right-color: blue;
  }
  .bottom-left {
    bottom: 0;
    left: 0;
    border-bottom-color: green;
    border-left-color: green;
  }
  .bottom-right {
    bottom: 0;
    right: 0;
    border-bottom-color: yellow;
    border-right-color: yellow;
  }
  .scan-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: red;
    animation: scanAnimation 2s infinite;
  }
  @keyframes scanAnimation {
    0% { top: 0; }
    50% { top: 100%; }
    100% { top: 0; }
  }
`;

document.head.insertAdjacentHTML("beforeend", `<style>${stylesCSS}</style>`);

export default InnerBoxScannerModal;
  

